/**
 * @author Rob Shepherd
 */
'use strict';

(function(origin){
	
	var SARDB = {
		
		menuItems: [],
		
		basicTeamMemberLevels: [
			{'level':500, 'name':'Team Member'},
			{'level':400, 'name':'Honorary Member'},
			{'level':300, 'name':'Support Member'},
			{'level':200, 'name':'Fundraiser'},
			{'level':100, 'name':'Non Member'},
			{'level':  0, 'name':'Other'},
		],
		
		url: function(path){
			if(path.indexOf('#') == 0){
				return path;
			}
			return origin + "/cohort" + path
		},
	};
	
	window.SARDB = SARDB;
})(window.location.origin);

(function(){

    Array.prototype.move = function (pos1, pos2) {
    // local variables
    var i, tmp;
    // cast input parameters to integers
    pos1 = parseInt(pos1, 10);
    pos2 = parseInt(pos2, 10);
    // if positions are different and inside array
    if (pos1 !== pos2 &&
        0 <= pos1 && pos1 <= this.length &&
        0 <= pos2 && pos2 <= this.length) {
        // save element from position 1
        tmp = this[pos1];
        // move element down and shift other elements up
        if (pos1 < pos2) {
            for (i = pos1; i < pos2; i++) {
                this[i] = this[i + 1];
            }
        }
        // move element up and shift other elements down
        else {
            for (i = pos1; i > pos2; i--) {
                this[i] = this[i - 1];
            }
        }
        // put element from position 1 to destination
        this[pos2] = tmp;
    }
}

/**
 * A multi-level groupBy for arrays inspired by D3's nest operator.
 * https://gist.github.com/joyrexus/9837596
 */
window.nest = function (seq, keys) {
    if (!keys.length)
        return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), function (value) { 
        return nest(value, rest)
    });
};


})()
'use strict'

;(function(){
	PNotify.prototype.options.styling = "fontawesome";

	PNotify.err = function(msg){
	    new PNotify({
	        title: 'Error',
	        text: msg,
	        type: 'error',
	        nonblock: {
	            nonblock: true,
	            nonblock_opacity: .2
	        }
	    });
	};
	
	PNotify.ok = function(msg){
	    new PNotify({
	        title: 'Success',
	        text: msg,
	        type: 'success'
	    });
	};

	PNotify.warn = function(msg){
	    new PNotify({
	        title: 'Warning',
	        text: msg,
	        type: 'warning'
	    });
	};
})();


'use strict'
;(function(){
	
	/* Glue for starting AngularJS with custom modules */
	angular.runSARDB = function(deps){
		
		var modules = ['angularModalService', 'ngSanitize','ui.select','sbHttpConfig','sbCommon'
			,'ui.bootstrap'
			,'ngAside'
			,'ng-confirmr'
			
			,'blockUI'
			,'ngFileUpload'
			,'sbAttrs'
			,'sbTemplateCache'
			,'sbMoment'
			,'sbDateEntry'
		];
		if(angular.isArray(deps)){
			modules = modules.concat(deps);
		}
		else if(angular.isString(deps)){
			modules.push(deps);
		}
		//console.log("Running ngApp sarDB with modules: " + modules.toString());
		var sarDB = angular.module('sarDB', modules )
		
	};
	
	
	
	angular.module('sbCommon', ['blockUI'])

	.run(['$rootScope', '$http', function($rootScope, $http){
		$rootScope.url = SARDB.url;

		$rootScope.$on("$routeChangeSuccess", function (event, currentRoute, previousRoute) {
			$( '[canvas="container"]' ).animate( {
				scrollTop: 0
			}, 150 );
		});

		$rootScope.getVersion = function(){
			$http.get( SARDB.url('/version')).then(function(resp){
				$rootScope.appVersion =  resp.data
			})
		}

	}])

	.run(['$rootScope', '$aside', '$location', function ($rootScope, $aside, $location) 
	{
		function openHelp(topic)
		{
			if(!topic){
				topic = openHelp.module + $location.path().replace(/\//g, '_')
			}
			else {
				topic = "help_" + topic;
			}
			var aside = $aside.open({
				templateUrl: SARDB.url("/gen/help/" + topic + ".html"),
				placement: 'left',
				size: 'md',
				controller: function($scope, $uibModalInstance) {
					$scope.asideClose = function(e) {
						console.log(e);
						$uibModalInstance.close();
						e.stopPropagation();
					};
				}
			});
		}
		$rootScope.openHelp = openHelp
		$rootScope.openHelp.module = ""
	
	}])

	.run(['$rootScope', function ($rootScope) 
	{
		if( typeof jsPageModel.perms === 'object' )
		$rootScope.perms = jsPageModel.perms;
	}])
	
	.factory('BreadSvc', [ function(){
		return {
			n: 0,
			crumbs:[],
			setBase: function(baseCrumbs){
				if(angular.isArray(baseCrumbs)){
					this.crumbs = baseCrumbs; this.n = this.crumbs.length;
				}
				else {
					this.crumbs = [baseCrumbs]; this.n = 1;
				}
			},
			setCrumbs: function(topCrumbs){
				this.crumbs = this.crumbs.slice(0,this.n);
				if(angular.isArray(topCrumbs)){
					this.crumbs = this.crumbs.concat(topCrumbs);
				}
				else {
					this.crumbs.push(topCrumbs);
				}
			}
		};
	}])
	
	.controller("BreadcrumbController", ['$scope', 'BreadSvc', function($scope, bread){
		
		$scope.breadcrumbs = [];
		
		$scope.$watch(function(){ return bread.crumbs; }, function(bcrumbs){
	  		$scope.breadcrumbs = bcrumbs;
	  	} );
	}])
	
	
	.controller("MenuController", ['$scope', 'MenuItemService', function($scope, menuSvc){
		$scope.topItems = [].concat(SARDB.menuItems);
	}])
	
	.directive("colorPicker", ['$parse', function($parse){

		// see main.less
		var colors = ["666666", "ada7fc", "ec6f86", "ffdd75", "9ff3c3", "4573e7", "d187ef", "fe816d",
            "daff75", "6aecf4", "7e69ff", "fda6f8", "ffba6d", "b2f068", "45b4e7", "ad61ed"];
		
		function link(scope,element,attrs){
			
				var modelAccessor = $parse(attrs.colorPicker);
				
				var onUpdate = function(id, newValue){
					scope.$apply(function(){

						if( newValue.charAt(0) == '#' ){
							newValue = newValue.substr(1);
						}

						modelAccessor.assign(scope, newValue);
					});
				};
				$(element).colorPicker({onColorChange : onUpdate, colors:colors });
		};
		
		return {
			restrict: 'A',
			link: link,
		}
	}])
	
	.directive("memberTag", function(){
		
		function link(scope, element, attrs) {
			
			if( !(scope.def) && scope.dto){
				scope.def = scope.dto.def
			}
			if( !(scope.instance) && scope.dto){
				scope.instance = scope.dto.instance
			}

			if( ! scope.def){ // fix for spurious ui-grid rows
				return;
			}
			var hexcolor = scope.def.colour
			
			if(hexcolor && (hexcolor.indexOf('#') == 0)){
				hexcolor = hexcolor.substr(1,7)
				scope.tagColourClass = "bg-color-" + hexcolor;
			}
			
	    }
		
		return {
			scope: {
			   dto: '=?' // the fieldTag DTO instance
			 , def: '=?' // the fieldTag DTO instance
			 , instance: '=?' // the fieldTag DTO instance
			 , onClick: '&'
			},
			restrict: 'E',
			link: link,
			templateUrl: function(el, attr){
				return attr.small == 'true' ? 
				    '/gen/partials/teamMember/partial-teamMemberTag-sm.html' : 
					'/gen/partials/teamMember/partial-teamMemberTag.html'
			},
		}
	})
	
	.config(['blockUIConfig', function(blockUIConfig) {

		// Change the default overlay message
		blockUIConfig.message = 'Please Wait...';

		// Change the default delay to 100ms before the blocking is visible
		blockUIConfig.delay = 0;

		// https://github.com/McNull/angular-block-ui#autoinjectbodyblock
		blockUIConfig.autoInjectBodyBlock = false;

		

		}]);
	
})();

(function(){
        console.log("Running script dateEntry.ng.js")
        angular.module('sbDateEntry', [])
            .directive('jqDateEntry', ['$timeout', function($timeout){
                console.log("Running directive dateEntry.ng.js")
                return {
                    require: 'ngModel',
                    restrict: 'A',
                    link: function(scope, elm, attrs, ngModel) {

                        ngModel.$formatters.push(function(value) {
                            if(!value){
                                return;
                            }
                            var mDate = moment(value);
                            if(mDate.isValid()){
                                var newFmt = mDate.format("YYYY-MM-DD")
                                return newFmt
                            }
                            
                          });
                        
                          //format text from the user (view to model)
                          ngModel.$parsers.push(function(value) {
                            if(!value){
                                return;
                            }
                            var mDate = moment(value);
                            if(mDate.isValid()){
                                return mDate.format("YYYY-MM-DDTHH:mm:ssZ")
                            }
                          });
                        
                        
                        ngModel.$render = function(){
                            var view_object = ngModel.$viewValue
                            if(!view_object){
                                return;
                            }
                            elm.datetextentry('set_date', view_object );
                        }

                        function onChange(fmt_date){
                            $timeout(function() {
                                ngModel.$setViewValue(fmt_date);
                            });
                        }

                        elm.datetextentry({on_change: onChange});
                    }
                }
            }])
            .directive('dateViewEdit', function(){
                return {
                    restrict: 'E'
                    ,replace: true
                    ,scope: {
                        date: "="
                        ,onSave: "&"
                    },
                    template: '<span><span ng-hide="edit"><a ng-click="edit=!edit"><i class="fa fa-calendar fa-fw"></i> {{date|moment:"DD/MM/YY"}}</a></span>' + 
                                '<span ng-show="edit"><input class="form-control" jq-date-entry ng-model="date" />&nbsp;<i ng-click="edit = !edit; onSave()" class="fa fa-check-circle"></i></span></span>'
                }
            })
})()
/**
 * @author Rob Shepherd
 */
'use strict';

;(function(){

angular.module('sbHttpConfig', [])

.run(['$rootScope', '$window', function($rootScope, $window){
}])

.directive('prefixHref', ['$timeout', function($timeout){
  return {
    restrict: 'A',
    priority: 90, // ng-href is 99 so we run a bit after

    compile: function (tElem, tAttrs) {
            return {
              post: function (scope, iElem, iAttrs) {
                $timeout(function(){
                  var href = iAttrs.href

                  if( typeof href === 'string' ){
                    if(href.indexOf('/') == 0){
                        iAttrs.href = SARDB.url(href);
                        //console.log(iElem, href, iAttrs.href);
                    }
                  }
                }, 500)
              }
            }
          }
/*
    link: function prefix(scope, iElement, iAttrs){
      //console.log(iAttrs.href)
      var href = iAttrs.href
      
      if( typeof href === 'string' ){
        if(href.indexOf('/') == 0){
            iAttrs.href = SARDB.url(href);
        }
      }
      //console.log(iAttrs.href)
    }
*/
  };
}])

.factory('httpHostAndContextInterceptor', ['$location', function(){
  
                      return {
                        request: function(config){
                            if(config.url.charAt(0) == '/' && config.url.indexOf('/gen/partials') != 0 ){
                              var newUrl = SARDB.url(config.url);
                              //console.log("rewriting URL:" + config.url + " to URL:" + newUrl)
                              config.url = newUrl;
                            }
                            return config
                        }
                      }
  
                      }])

.factory('httpRedirectionInterceptor', ['$location', function($location) {
                    return {
                      'response': function(response) {
							
													var nextUri = response.headers("X-WebClient-NextURI");
													
													if(!!(nextUri)){
														if(nextUri.indexOf("/") == 0){
															window.location.href = SARDB.url(nextUri); 
														}
													}
													
							
                        							if(!!(response) && !!(response.data)  && response.data.hasOwnProperty("redirect")){
                        								window.location.href = response.data.redirect;
                        								return null;
                        							}
                        							else {
                        								return response;
                        							}
                                  }
                           };
                          }])
.factory('httpOKButErrorWritingInterceptor', ['$location', function($location) {
                    return {
                      'response': function(response) {
										if( (!!(response.data.type)) && response.data.type == "OKButErrorWriting"){
											PNotify.err("Your thing was saved, but something bad happened when responding. try refreshing the page");
											response.data = null;
										}
										return response;			
                                  }
                           };
                          }])                          
                          
.factory('httpErrorNotificationInterceptor', ['$q', function($q) {
                    return {
                      'responseError': function(response) {
                      						
                      						if( !!(response.config.nowarn) ){
                      							return $q.reject(response);
                      						}
                      						
                            				var data = response.data;
                            				if(!data){
                                            	PNotify.err(response.statusText);
                                            	//return response;
                                            }
											              else if(typeof data == 'string'){
                                            	PNotify.err(data);
                                            	//return response;
                                          	}
											              else if(angular.isArray(data)){
                                        var txt = "";
                                        for(var i=0;i<data.length;i++ ){
                                              txt = txt + "<strong>" + i + "</strong>: " + data[i] + "<br>";
                                        }
                                        PNotify.err(txt);
                                        //return response;
                                    }
                                    else if( data.hasOwnProperty("errors")){
                                        var txt = "";
                                        for(var i=0;i<data.errors.length;i++){

                                          var error = data.errors[i];

                                          if(typeof error === 'string'){
                                              if(error == 'net._95point2.core.X$HttpException'){
                                                error = "Error:"
                                              }
                                              txt = txt + " " + error;
                                          }
                                          else if(error && error.hasOwnProperty("field") && error.hasOwnProperty("defaultMessage")){
                                              txt = txt + "<strong>" + error.field + " " + error.defaultMessage + "</strong><br>";
                                          }
                                          else {
                                            txt = txt + " " + JSON.stringify(error);
                                          }
                                        }
                                        PNotify.err(txt);
                                    }
                                    else {
                                        var txt = "";
                                        for(var propertyName in data ){
                                          if(data.hasOwnProperty(propertyName)){
                                            txt = txt + "<strong>" + propertyName + "</strong>: " + data[propertyName] + "<br>";
                                          }
                                        }
                                        PNotify.err(txt);
                                    }

                                    return $q.reject(response);
                                          

                                          
                                  }
                           };
                          }])                          
      
.factory('httpIndicatorInterceptor', [ '$q', function($q) {

                var count = 0;
                var unblockTimer = null;

                var onReq = function(config) {
                                if(count < 1){ // should block
                                        if(unblockTimer){ // something is waiting to block
                                                clearTimeout(unblockTimer); // cancel (remain blocking)
                                                unblockTimer = null; // bin the timer
                                        }
                                        else { // nothing waiting to block
                                                //$.blockUI({ message: '<h2><img src="img/ajax-loader.gif" /></h2>' }); // block
                                        }

                                        count=0; // reset in case of counter propbs
                                }
                                count++; // another concurrent request maybe? increment outward requests
                                return config;
                     };

                var onResp = function(response){
                                --count; // a requst is back
                                if(count < 1){ // if last one, then we could unblock
                                        unblockTimer = setTimeout(function(){ // wait before blocking, to prevent flicker.
                                                //$.unblockUI();
                                                unblockTimer = null;
                                        },800);
                                        count=0; // reset in case of counter probs
                                }
                                return response;
                    };

                    var onErr = function(rejection){
                        onResp();
                        return $q.reject(rejection);
                    };

                    return {
                      request: onReq,
                      response:onResp,
                      requestError:onErr,
                      responseError:onErr
                        };
                          }])

                .config(['$httpProvider', function($httpProvider){
                        $httpProvider.interceptors.push('httpHostAndContextInterceptor');
                        $httpProvider.interceptors.push('httpRedirectionInterceptor');
                        $httpProvider.interceptors.push('httpErrorNotificationInterceptor');
                        $httpProvider.interceptors.push('httpIndicatorInterceptor');
                        $httpProvider.interceptors.push('httpOKButErrorWritingInterceptor');
                }]);

})();

(function(){
    
        angular.module('sbMoment', [])
        .filter('moment', momentFilter)
        .constant('momentize', {
            incident: function(inc){
    
                momentise(inc,'alertedAt')
    
                _(inc.alerts).each(function(alert){
                    momentise(alert,'alertedAt')
                })
    
                _(inc.reports).each(function(report){
                    momentise(report,'startedAt');
                    momentise(report,'finishedAt');
                    momentise(report,'created');
                    momentise(report,'updated');
                })
    
                return inc;
    
            }
        })
    
        function momentFilter() {
          var filter = function(item, format, parseFormat) {
    
              if(!item){
                  return null;
              }
    
              var the_moment;
    
              if(moment.isMoment(item))
              {
                    the_moment = item;
              }
              else if(typeof item === 'string' && typeof parseFormat === 'string')
              {
                    the_moment = moment(item,parseFormat);
              }
              else{
                  the_moment = moment(item);
              }
    
              if(format == "fromNow"){
                  return the_moment.fromNow();
              }
              else {
                  return the_moment.format(format);
              }
    
          }
    
          filter.$stateful = true;
          return filter;
        }
    
        function momentise(obj, prop){
            if( obj[prop] == null || typeof obj[prop] === 'undefined' ){
                return;
            }
            obj[prop] = moment(obj[prop])
        }
    
    })();
    /* */
'use strict'
;(function(){
	
	angular.module('sbDirectory', ['ngRoute', 'ng-confirmr'])
	
	.config(['$routeProvider',
	  function($routeProvider) {
	    $routeProvider
		/*
		.
	      when('/', {
	        redirectTo: '/list'
	      })
		  */
		  
		  .
	      when('/', {
	        templateUrl: '/gen/partials/directory/browse.html',
	        controller: 'DirectoryBrowseController'
	      })
		  
		  /*.
	      when('/admin', {
	        templateUrl: '/gen/partials/team/admin.html',
	        controller: 'TeamAdminController'
	      }).
		  when('/admin-advanced', {
	        templateUrl: '/gen/partials/team/admin-advanced.html',
	        controller: 'TeamAdminAdvController'
	      }).
	      when('/members/add', {
	        templateUrl: '/gen/partials/team/newMember.html',
	        controller: 'TeamMemberController'
	      }).
	      when('/members/list', {
	        templateUrl: '/gen/partials/team/listMembers.html',
	        controller: 'TeamMemberListController',
	      })
		  */
		  .
	      otherwise({
	        redirectTo: '/'
	      });
	  }])
	  .run(['$rootScope', function($rootScope){
	  	$rootScope.$on('$routeChangeStart', function(prev, next){});
	  }])
	  
	  
	  .run([ 'BreadSvc', function(bread){
	  		bread.setBase( { label:"Directory", link:SARDB.url('/directory/')  } );
	  }])
	  
	  
	 
	 .run(['$rootScope', function ($rootScope) 
	{
		$rootScope.openHelp.module = "directory";
	}])

	 
	
	.controller("DirectoryBrowseController", ['$scope', 'BreadSvc', function($scope,bread){
		bread.setCrumbs(  {label:'Regions'} );
		$scope.msg = "go team!";

		/** Begin data chomping */
		// creates a double index { region1: { team1: [{a..,},{b..,}], team2: [{a..,},{b..,}] }, region2: {....} }
		var byRegion = _.groupBy(_.toPairs(_.groupBy(jsPageModel.directory, 'team')), function(a){ return a[1][0].region; })

		_.forEach(_.keys(byRegion), function(r){
				byRegion[r] = _.fromPairs(byRegion[r])
		})

		$scope.directory = byRegion;
	}])
	
	
	
	


	
	

















	
	
	
	
	
})();

'use strict'
;(function(){
	
	angular.module('sbTeam-bulk', [])

    .controller("TeamAdminBulkMembersController", 
                    ['$scope', 'BreadSvc', 'EntitySvc', '$http', '$window', 'ModalService', '$route', '$routeParams', '$timeout', 'orderByFilter', 'attrService',
    function($scope,bread,entities,$http,$window, modal, $route, $routeParams, $timeout, orderBy, attrService){
        
        var vm = this;

        var team = jsPageModel.team;
        var attrs = jsPageModel.attrs;

        vm.team = jsPageModel;

        
        vm.input = null;

        vm.bulkData = [];

        if (window.File && window.FileReader) 
        {
            vm.showUploadForm = true;
        } 
        else {
            vm.showUploadForm = false;
            alert('The File APIs are not fully supported in this browser. Please use Chrome, Firefox or Safari');
        }

        function setupBulkData(rows)
        {
                // filter empty rows
                rows = _.filter(rows, function(row){ return _.isArray(row) && row.length > 1 })

                if(rows.length == 0){
                    alert("No data")
                    return;
                }

                var maxCols = 0;
                for(var r=0;r<rows.length;r++)
                {
                    var row = rows[r];
                    maxCols = Math.max(maxCols, row.length);
                    row.unshift(null); // this holds the memberType/ignore
                }

                // setup the data container
                var bulkData = {
                    rows: rows,
                }

                bulkData.columnConfigs = _.times(maxCols+1, function(i){ return { colIndex: i, attr: null, qual:null }});

                //first column
                bulkData.columnConfigs[0].attr = "memberType";


                vm.bulkData = bulkData;
                vm.showUploadForm = false;
        }

        vm.readFile = function(){
            if(!vm.input || !vm.input.file){
                return;
            }

            function complete(data, file){
                $scope.$apply(function(){
                    setupBulkData(data.data);
                })
            }

            var parseOpts = {
                header: false,
                delimiter: ',',
                complete: complete
            }

            vm.data = Papa.parse(vm.input.file, parseOpts);
        }


        vm.doUpload = function(){
            $http.post("/api/v1/team/" + team.id + "/bulkAddMembers", vm.bulkData)
            .success(function(){
                vm.bulkData = null;
                vm.showUploadForm = true;
                PNotify.ok("Success");
            })
        }


    }])

    .directive("bulkFileChooser", [function(){

        function link(scope, el  ,attrs){
            function onFileSelect(evt){
                if(evt.target.files && evt.target.files.length > 0){
                    scope.$apply(function(){
                        var ifl = evt.target.files[0];
                        
                        if(ifl.type != "text/csv"){
                            return alert("No CSV")
                        }

                        scope.input = {
                            name: ifl.name,
                            type: ifl.type,
                            file: ifl
                        }
                    })
                }
            }

            el[0].addEventListener('change', onFileSelect, false);
        }

        return {
            restrict:'A',
            link: link,
            scope: {
                input: '=bulkFileChooser'
            }
        }

    }])
    
})();
'use strict'
;(function(){
	
	angular.module('sbTeam', ['ngRoute', 'ng-confirmr', 'sbTeam-bulk'])
	
	.config(['$routeProvider',
	  function($routeProvider) {
	    $routeProvider.
	      when('/', {
	        redirectTo: '/members/list'
	      }).
	      when('/activity', {
	        templateUrl: '/gen/partials/team/activity.html',
	        controller: 'TeamActivityController'
	      }).
	      when('/admin', {
	        templateUrl: '/gen/partials/team/admin.html',
	        controller: 'TeamAdminController'
	      }).
		  when('/admin-advanced', {
	        templateUrl: '/gen/partials/team/admin-advanced.html',
	        controller: 'TeamAdminAdvController'
	      }).
		  when('/admin-tag-integrations', {
	        templateUrl: '/gen/partials/team/admin-tag-integrations.html',
	        controller: 'TeamAdminTagIntegrationsController'
	      }).
		  when('/admin-bulkload', {
	        templateUrl: '/gen/partials/team/admin-bulkload.html',
	        controller: 'TeamAdminBulkMembersController as vm'
	      }).
	      when('/members/add', {
	        templateUrl: '/gen/partials/team/newMember.html',
	        controller: 'TeamMemberController'
	      }).
	      when('/members/list/:q?', {
	        templateUrl: '/gen/partials/team/listMembers.html',
	        controller: 'TeamMemberListController',
	      }).
		  when('/members/list', {
	        redirectTo: '/members/list/',
	      }).
	      otherwise({
	        redirectTo: '/activity'
	      });
	  }])
	  .run(['$rootScope', function($rootScope){
	  	$rootScope.$on('$routeChangeStart', function(prev, next){});
	  }])
	  
	  .run([ 'BreadSvc', function(bread){
	  	var team = jsPageModel.team;
	  	if(!!team){
	  		bread.setBase( {label:team.id, link:SARDB.url('/team/' + team.id + "/") } );
	  	}
	  }])
	  
	 .factory('EntitySvc', function(){
	 	return {
	 		team: jsPageModel.team
	 	};
	 })
	 .run(['$rootScope', function ($rootScope) 
	{
		$rootScope.openHelp.module = "team";
	}])















	/**
	 * 
	 * Member Grid
	 * 
	 * 
	 * 
	 */

	 .controller("TeamMemberListController", ['$scope', 'BreadSvc', 'EntitySvc', '$http', '$window', 'ModalService', '$route', '$routeParams', '$timeout', 'orderByFilter', 'attrService',
	 	 function($scope,bread,entities,$http,$window, modal, $route, $routeParams, $timeout, orderBy, attrService){
		
		var theGrid = null;

		$scope.accountAttrs = [
			{id:'name', name:'Name', type:'fixed'},
			{id:'email', name:'Email', type:'adminOnly'},
			{id:'cellPhone', name:'Mobile Phone', type:'adminOnly'},
			{id:'memberType', name:'Member Type', type:null}
		]
		
		bread.setCrumbs(  {label:'Member List'} );
		
		$scope.team = entities.team;
		$scope.searchConfig = {showFields:{}, showTags:{}, showAccountAttrs:{'name':true}, showMemberTypes:{}, showTagGroupAgg:{}, showAll:false, grouped:true };
		$scope.members = [];
		$scope.fieldGroups = {};
		$scope.configure = false;

		/** set all the tags in a group to false, used when the "All Group" button is toggled */
		$scope.clearGroupTags = function(group)
		{
			_.each(group.fields, function(t){
				$scope.searchConfig.showTags[t.id] = false
			})
		}

		$scope.subgroupExpanded = {};

		$scope.membershipLevels = partitionMembershipLevels(jsPageModel.membershipLevels);
		
		/**
		 * load up & transform all the attributes
		 */
		 
		/*
		 * creates the following [ { group:GroupName, fields: [ fields... ] }  ]
		 */
		 $scope.fieldGroups = _(jsPageModel.attrs)
		 							.filter(isField)
									.groupBy("group")
									.toPairs()
									.transform(function(res,arr){  return res.push( { group:arr[0], fields:arr[1] }) })
									.sortBy("group")
									.value();

		 $scope.tagDefGroups = _(jsPageModel.attrs)
		 							.filter(isTag)
									.groupBy("group")
									.toPairs()
									.transform(function(res,arr){  return res.push( { group:arr[0], fields:arr[1] }) })
									.sortBy("group")
									.value();
		
		function setSearch(query){
			var str = JSON.stringify(query);
			var b64 = btoa(str)
			$route.updateParams({q:b64})
		}

		/** Quick List  */
		$scope.openQuickList = function(){
			return modal.showModal({
		      templateUrl: "quickListModal.html",
		      controller: "QuickListModalController",
		      inputs: { // provided to controller constructor named dependencies
		      	 fields: $scope.fieldGroups,
		      	 tags: $scope.tagDefGroups,
				 attrs: $scope.accountAttrs,
				 goFn: setSearch
		      }
		    }).then(function(modal) {
		      modal.element.modal();
			  return modal;
		    });
		}

		$scope.updateGrid = function()
		{
			var srch = {fields:[], tags:[], accountAttrs:[], memberTypes:[], tagGroupAgg:[], showAll: false };

			srch.fields = _.keys( _.pickBy($scope.searchConfig.showFields, _isTrue ) ); // => ['fDefId1', 'fDefId2', 'fDefId3']
			srch.tags = _.keys( _.pickBy($scope.searchConfig.showTags, _isTrue ) ); // => ['tDefId1', 'tDfId2', ...]
			srch.accountAttrs = _.keys( _.pickBy($scope.searchConfig.showAccountAttrs, _isTrue ) ); // => ['name', 'email', 'cellPhone', 'memberType', ... ]
			srch.memberTypes = _.keys( _.pickBy($scope.searchConfig.showMemberTypes, _isTrue ) ); // => ['memberType1', 'memberType2', ... ]
			srch.tagGroupAgg = _.keys( _.pickBy($scope.searchConfig.showTagGroupAgg, _isTrue ) ); // => ['tagGroup1', 'tagGroup2', ... ] // to be aggregated

			srch.showAll = $scope.searchConfig.showAll;
			srch.grouped = $scope.searchConfig.grouped;

			if(srch.memberTypes.length == 0){
				srch.memberTypes.push("*")
				if(srch.accountAttrs.indexOf("memberType") == -1){
					srch.accountAttrs.push("memberType")
				}
			}

			setSearch(srch);

			return true;
		};
		
		// called when the URL params have been parsed to fetch data as per the params
		var loadGridData = function(srch){
			$http.post("/api/v1/team/" + $scope.team.id + "/members/grid/_search", srch)
					.success(function(data) {

						if(data.length == 0){
							PNotify.warn("No Results")
							$scope.configure = true;
							updateGridInternal(data, srch);
						}
						else {
							updateGridInternal(data, srch);
							$scope.configure = false;
							jQuery('div#member-grid').scrollTop();
						}
						
						
						// move to grid Top
					});
		}

		/** 
		  	BEGIN membership level sorting 
		*/

		function findInMembers(m){
			return _.findIndex($scope.membershipLevels.members, function(l){ return l.name === m });
		}

		function findInNonMembers(m){
			return _.findIndex($scope.membershipLevels.nonMembers, function(l){ return l.name === m });
		}

		var getLevelRank = _.memoize(function(m){

			var mi = findInMembers(m)
			if(mi != -1){
				return mi;
			}
			else {
				var nm = findInNonMembers(m)
				if(nm != -1){
					return 1000+nm;
				}
			}

			return 100000;
		});

		/**
		    END membership level sorting  
		*/

		// can't get prefix-href to work in grid :( use a wanky click event instead
		$scope.goMember = function(team, member){
			$window.location = SARDB.url('/team/' + team + '/member/' + member);
		}

		/** 
		 *     a new set of data from the backend
		 * 
		 *  setup the whole display grid
		 */
		var updateGridInternal = function(data, srch)
		{
			// 1. setup the columns

			var columns = [];
			var memberProps = [];

			// show memberType grouping if we've asked for more than one (or all '*') types
			if(srch.memberTypes.length > 1 || srch.memberTypes.indexOf("*") >= 0 ){

				// TODO custom sort function for member type based on membership levels
				if(!srch.grouped) { 
					columns.push({ name:'Member Type', field: 'memberType', editable:false, cellTemplate:'cell-tpl-text.html' });
				}
				//var groupThemUp = true; // TODO
			}
			// otherwise show one if we asked for it.
			else if(srch.accountAttrs.indexOf('memberType') >= 0){
				columns.push({name:'Member Type', field: 'memberType', cellTemplate:'cell-tpl-text.html'  })
			}

			// always show the name
			columns.push({name:'Name', field:'name', cellTemplate:'cell-tpl-name-link.html', sortable:true, editable:false})


			_.each($scope.accountAttrs, function(attr){
				if(attr.id == 'name' || attr.id == 'memberType'){ return; }

				if(srch.accountAttrs.indexOf(attr.id) >= 0){
					columns.push({name:attr.name, field:attr.id, sortable:true, editable:false, cellTemplate:'cell-tpl-text.html' })
				}
			})

			_.forEach($scope.tagDefGroups, function(tagDefGroup){

				if(srch.tagGroupAgg && srch.tagGroupAgg.indexOf(tagDefGroup.group) > -1)
				{
					columns.push({name:tagDefGroup.group, field: "tagGrp-" + tagDefGroup.group, cellTemplate: 'cell-tpl-tag-group.html'})
					// old template: '<div class="tag-holder" ng-repeat="tag in grid.getCellValue(row,col)"><member-tag def="tag.def" instance="tag" ></member-tag></div>'
					memberProps.push( "tagGrp-" + tagDefGroup.group );
				}

				_.forEach(tagDefGroup.fields, function(t){
					if(srch.tags.indexOf(t.id) > -1){
						columns.push({name:t.name, field:t.id, cellTemplate:'cell-tpl-tag.html', editable:true })
						memberProps.push( t.id );
					}
				})
			})

			_.forEach($scope.fieldGroups, function(fieldGroup){
				_.forEach(fieldGroup.fields, function(field){
					if(srch.fields.indexOf(field.id) > -1){
						columns.push({name:field.name, field:field.id, sortable:true, editable:true, cellTemplate:'cell-tpl-attr.html' })
						memberProps.push( field.id );
					}
				})
			})

			$scope.newGrid = {};
			$scope.newGrid.columns = columns;

			// enable filtering? 
			var filter = true;

			function notPresentOrEmpty(arr){
				return arr == null || _.isEmpty(arr);
			}

			if(srch.showAll){
				filter = false;
			}
			else if( _.every([srch.tagGroupAgg, srch.tags, srch.fields], notPresentOrEmpty )   ){
				filter = false;
			}

			// 2 manipulate the data into the membership level groups
			// filter the members based on who's got what set // TODO optionalise this!
			if(filter){
				data = _.filter(data, function(m){
					return _.some(memberProps, _.partial(_.has, m)) // filtering: return true if {m} "has" "some" of the {memberProps}, false if "has" !"some"
 				})
			}

			$scope.newGrid.grouped = srch.grouped;

			var groupKey = srch.grouped ? 'memberType' : 'dummy'

			var grouped = _.sortBy(_.map(_.groupBy(data, groupKey), function(v,k){
				return {memberType: k, members:v};
			}), function(mt){
				var rank = getLevelRank(mt.memberType)
				return rank
			} )

			$scope.newGrid.data = grouped;

			$scope.newGrid.q = srch;

			$scope.updateSort('name');
	
		};

		$scope.reloadShowAll = function(showAll)
		{
			var srch = $scope.newGrid.q;

			srch.showAll = showAll;

			setSearch(srch);
		}

		$scope.updateSort = function(field){
			if(field == $scope.newGrid.sort){
				$scope.newGrid.reverse = !$scope.newGrid.reverse;
			}
			else {
				$scope.newGrid.sort = field;
				$scope.newGrid.reverse = false;
			}

			//console.log($scope.newGrid.sort, $scope.newGrid.reverse)

			_.each( $scope.newGrid.data, function(o){
				o.members = orderBy(o.members, getSortField, $scope.newGrid.reverse);
			})
		}

		function getSortField(a){
			return _.get(a, $scope.newGrid.sort)
		}

		$scope.getCellAttrInstanceVal = function(member, col){
			var attr = _.get(member, col.field)

			if( _.get(attr,"def.subType") == "Date"){
				var date =  _.get(attr, "instance.value");
				if(date){
					return moment(date).format("DD/MM/YYYY")
				}
				else return null;
			}

			// other cell types
			return _.get(attr, "instance.value");
		}

		$scope.getCellRaw = function(member, col){
			return _.get(member, col.field)
		}

		$scope.getCellTpl = function(member,col){
			var val = _.get(member, col.field);

			if(!val && col.editable ){
				return 'cell-tpl-absent.html';
			}

			if( typeof col.cellTemplate === 'string' ){
				return col.cellTemplate;
			}
			else {
				return "cell-tpl-text.html"
			}			
		}

		
		var exporterCallback = function( member, tagFieldId ) {

			var rawVal = _.get(member, tagFieldId);

			if( tagFieldId.indexOf('tagGrp-') > -1 && _.isArray(rawVal) ){
				var out = _.map(rawVal, function(tagInstance){return tagInstance.def.name})
				return _.join(out, ", ")
			}
			else if( (rawVal != null) && (typeof rawVal == 'object') && rawVal.def){
				return rawVal.def.name
			}
			else {
				return rawVal;
			}
		}
		

		$scope.download = function(){
			//console.log($scope.grid)
			//console.log(theGrid)
			var groups = $scope.newGrid.data;
			
			var sheet = {};

			var cols = $scope.newGrid.columns

			cols = [{name:'Member Type', field:'memberType'}].concat(cols)
 
			cols.forEach(function (col, i) {
				var loc = XLSX.utils.encode_cell({r: 0, c: i})
				
				sheet[loc] = {
					v: col.name,
					t: 's'
				};

				col.maxWidth = col.name.length;
			});
			
			var endLoc;

			var rowIndex = 0;
			_.each(groups, function(grp){

				_.each(grp.members, function(member){
					rowIndex++
					var colOffset = 0;
					cols.forEach(function (col, ci) {

						var loc = XLSX.utils.encode_cell({r: rowIndex, c: ci});

						var value = exporterCallback(member, col.field);

						sheet[loc] = {
							v: value,
							t: 's'
						};

						var width = value ? value.length : 0;
						col.maxWidth = Math.max(width, col.maxWidth);
						
						endLoc = loc;
					});

				})

			})

			var colDefs = _.map(cols, function(c){
				return {wch:c.maxWidth}
			})
			
			sheet['!ref'] = XLSX.utils.encode_range({ s: 'A1', e: endLoc });
			sheet['!cols'] = colDefs;

			var workbook = {
				SheetNames: ['Sheet1'],
				Sheets: {
					Sheet1: sheet
				}
			};

			var wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
			var wbout = XLSX.write(workbook, wopts);
			//console.log(sheet)
			saveAs(new Blob([s2ab(wbout)], {type: ""}), "MemberReport.xlsx");
			
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
				return buf;
			}

		}
		



		// Now load the query from the URL param
		var qParam = $routeParams['q'];

		// set the default
		var q = {
			accountAttrs:['name'], 
			memberTypes:['*'],
			fields:[],
			tags:[],
			grouped: true,
			showAll: true
		}

		// parse out the base64 query
		if(qParam){
			var qStr = atob(qParam);
			if(typeof qStr === 'string'){
				try {
					q = JSON.parse(qStr);
				}
				catch(e){
					PNotify.err("Oops, Error with JSON parse");
				}
			}
			loadGridData(q)
		}
		else
		{
			/*
			* first load? we already have the teamMember list in jsPageModel.
			*/
			var baseData = _(jsPageModel.teamMembers).map(function(mq){ return { name: mq.firstName + " " + mq.lastName, memberType: mq.memberType, id: mq.memberId  } }).value();
			updateGridInternal(baseData, q);
		}


		
		


		/* member search */
		$scope.clearSearch = function(){
			$scope.memberSearch = '';
		}



		/**
		 * Attribute editting
		 */
		function addTag(member, tagDto){
			function onAdd(newTag){
				PNotify.ok("Tag Added: " + newTag.def.name + " to " + member.username)
				member[newTag.def.id] = newTag;
			}

			tagDto.instance = {
				value: null
			};

			attrService.addTag(tagDto, jsPageModel.team.id, member.id, onAdd);
		}

		function addField(member, fieldDto){
			function onAdd(newField){
				member[newField.def.id] = newField;
				PNotify.ok("Attribute Added: " + newField.def.name + " to " + member.username)
			}

			fieldDto.instance = {
				value: null
			};

			attrService.addField(fieldDto, jsPageModel.team.id, member.id, onAdd);
		}

		$scope.addAttribute = function(member, col)
		{
			var attrDef = _.find(jsPageModel.attrs, function(a){ 
							return a.id == col.field
						});

			if(!attrDef){
				PNotify.err("Ooops, cannot find this attribute")
				return;
			}

			var attr = {
				def: attrDef
			}

			if(attrDef.type == 'AppRole'){
				// noop
			}
			else if(attrDef.type == 'Tag'){
				addTag(member, attr)
			}
			else {
				addField(member, attr)
			}
			
		}

		
	}])

	.controller("QuickListModalController", ['fields', 'tags', 'attrs', 'close', '$element', '$scope', 'goFn', function(fields, tags, attrs, close, $element, $scope, goFn){
		$scope.attrs = attrs;

		var merged = {};
		_.each(fields, function(fieldGroup){
			if( ! merged.hasOwnProperty(fieldGroup.group) ){
				merged[fieldGroup.group] = [];
			}
			_.each(fieldGroup.fields, function(field){
				field.fieldOrTag = 'field'
				merged[fieldGroup.group].push(field)
			})
		})
		_.each(tags, function(fieldGroup){
			if( ! merged.hasOwnProperty(fieldGroup.group) ){
				merged[fieldGroup.group] = [];
			}
			_.each(fieldGroup.fields, function(field){
				field.fieldOrTag = 'tag'
				merged[fieldGroup.group].push(field)
			})
		});
		$scope.merged = merged;

		$scope.$close = function(){
			$element.modal('hide');
			close("cancel", 500); // mark as cancelled
		};

		$scope.go = function(type, ft){
			var q = {
				accountAttrs:['name', 'memberType'], 
				memberTypes:['*'],
				fields:[],
				tags:[],
				tagGroupAgg:[],
				grouped: true
			}
			if(type === 'field'){
				q.fields.push(ft.id);
			}
			if(type === 'tag'){
				q.tags.push(ft.id);
			}
			if(type === 'attr'){
				q.accountAttrs.push(ft.id);
			}
			if(type === 'tagGroup'){
				_.each(merged[ft], function(item){ if(item.fieldOrTag == 'field'){ q.fields.push(item.id) }})
				q.tagGroupAgg.push(ft);
			}
			goFn(q)
			$element.modal('hide');
			close("cancel", 500); // mark as cancelled
		}
		
	}])

	.controller("AttrEditModalController", ['attr', 'groups', 'close', '$element', '$scope', 'onSave', 'onDelete', function(inattr, groups, close, $element, $scope, onSave, onDelete){
		
		$scope.attr = _.clone(inattr);
		$scope.groups = groups;

		var check = function(){
			if($scope.attr.subType == 'Choice'){
				_.remove($scope.attr.options, function(opt){
					return ( opt == '' || opt == null );
				});
				
				if($scope.attr.options.length < 2){
					PNotify.err("Need 2 or more options");
					if($scope.attr.options.length == 0){
						$scope.addNewFieldOpt();
					}
					return false;
				}
			}
			return true;
		};
		
		$scope.$watch('attr.subType', function(type){
			if(type == 'Choice'){
				$scope.attr.options = [];
				$scope.addNewFieldOpt();
			}
			else {
				delete $scope.attr.options;
			}
		});
		
		$scope.addNewFieldOpt = function(){
			$scope.attr.options.push("");
		};

		$scope.$close = function(){
			$element.modal('hide');
			close("cancel", 500); // mark as cancelled
		};

		$scope.save = function(){
			if(!check()){
				return;
			}

			onSave($scope.attr).then(function(res){
				if(res){
					$element.modal('hide');
					close("ok", 500); // mark as cancelled
				}
				else {
					// there was a problem saving
				}
			});
		}

		$scope.delete = function(){
			onDelete($scope.attr).then(function(res){
				if(res){
					$element.modal('hide');
					close("ok", 500); // mark as cancelled
				}
			})
		}

		/**
		 * expiry/validity
		 */

		$scope.$watch("validityQual", function(newQual){
			if($scope.validityQuan && newQual){
				$scope.attr.defaultExpiry = $scope.validityQuan + newQual;
			}
			
		});

		$scope.$watch("validityQuan", function(newQuan){
			if($scope.validityQual && newQuan){
				$scope.attr.defaultExpiry = newQuan + $scope.validityQual;
			}
		});


		function getValidityParts(){
			if( _.get($scope, 'attr.defaultExpiry') != null  )
			{
				return /^([0-9]+)([DMY])$/.exec($scope.attr.defaultExpiry);
			}
			else {
				return null;
			} 
		}
		var getQuan = function(){
			var q = getValidityParts();
			if(!q){ return null }
			else { 
				var n = parseInt(q[1]);
				if(n == NaN){
					return null;
				}
				else {
					return n;
				}
			}
		}
		var getQual = function(){
			var q = getValidityParts();
			if(!q){ return ""}
			else { return q[2] }
		}

		// setup the component models.
		$scope.validityQuan = getQuan();
		$scope.validityQual = getQual();







	}])

	




	
	.controller("TeamActivityController", ['$scope', 'BreadSvc',  function($scope,bread){
		bread.setCrumbs(  {label:'Latest Activity'} );
		$scope.msg = "go team!";
	}])
	
	
	
	
	
	/**** 
	 * 
	 * 
	 * 
	 * 
	 * ADMIN 
	 * 
	 */
	
	.controller("TeamAdminController", ['$scope', 'BreadSvc', '$http', 'EntitySvc', 'ModalService', '$q', function($scope,bread, $http, entities, modal, $q){
		
		var theTagGrid;
		
		bread.setCrumbs( {label:'Admin',link:'#/admin'}, {label:'New Member'} );
		
		$scope.team = entities.team;

		/** MemberQuick objects */
		$scope.teamMembers = _(jsPageModel.teamMembers).sortBy( ['memberType', 'lastName']).value(); // plain list sorted by level & name
		$scope.teamMemberIndex = _($scope.teamMembers).keyBy("memberId").value(); // indexed by memberId
		
		
		/** FIELDS **/
		$scope.fields = [];
		$scope.tags = [];
		$scope.attrExpanded = 'none';
		$scope.expandAttr = function(t){
			if($scope.attrExpanded == t){
				t = 'none';
			}
			$scope.attrExpanded = t;
		}


		function loadFields(fieldList)
		{
			$scope.fields = _(fieldList).sortBy(['group', 'displayGroup', 'name']).value();
			$scope.fieldsGrouped = _($scope.fields).groupBy('group').value();
		}

		function loadTags(tagList)
		{
			$scope.tags = _(tagList).sortBy(['group', 'displayGroup', 'name']).value();
			$scope.tagsGrouped = _($scope.tags).groupBy('group').value();
		}

		/*
		 * on page load, grab them from the jsPageModel
		 */
		loadFields( _(jsPageModel.attrs).filter(isField).value() )
		loadTags( _(jsPageModel.attrs).filter(isTag).value() )
		
		/**
		 * Show a new or 
		 */
		$scope.showAttributeEditModal = function(attr){
			
			if(typeof attr === 'string'){
				if(attr == 'tag')
				{
					attr = {
						public: true,
						type: 'Tag',
						colour: 'ec6f86'
						,app:'teamdb'
					}
				}
				else {
					attr = {
						public: true
						,type: 'Field'
						,app:'teamdb'
					}
				}
			}

			var tpl = getAttrEditModalTpl(attr.type);

			return modal.showModal({
		      templateUrl: tpl,
		      controller: "AttrEditModalController",
		      inputs: { // provided to controller constructor named dependencies
		      	 attr: attr,
				 onSave: updateAttr,
				 onDelete: deleteAttr,
				 groups: $scope.groups
		      }
		    }).then(function(modal) {
		      modal.element.modal();
			  return modal;
		    });

		}

		function getAttrEditModalTpl(type){
			if(type == 'Tag') {return 'tagDefEditModal.html'}
			if(type == 'Field'){ return 'attrDefEditModal.html'}
			else {
				throw "Cannot find template for: " + type
			}
		}

		var deleteAttr = function(attr)
		{
			if(confirm("Really delete " + attr.name + "?"))
			{
				return $http.delete("/api/v1/team/" + jsPageModel.team.id + "/memberAttributeDef/" + attr.id)
				.then(function(resp){
					
					var attrs = [];
					if(newAttr.type == 'Tag'){ attrs =  $scope.tags }
					else if(newAttr.type == 'Field'){ attrs = $scope.fields; }
					else { throw "cannot use " + newAttr.type }
					_.remove( attrs, function(eattr){ return eattr.id == attr.id } )

					var reload = _.noop;
					if(newAttr.type == 'Tag'){ reload = loadTags }
					else if(newAttr.type == 'Field'){ reload = loadFields }
					else { throw "cannot use " + newAttr.type }
					reload( attrs )	


					return true;
				});
			}
			else {
				return $q.when(false);
			}
		}

		/* return promise chain from $http */
		var updateAttr = function(attr){

			var existing = !!(attr.id);

			return $http.post("/api/v1/team/" + jsPageModel.team.id + "/memberAttributeDef", attr)
				.then(function(resp){ 
					var newAttr = resp.data;

					if(!newAttr){ return false; }
					
					var attrs = [];
					if(newAttr.type == 'Tag'){ attrs =  $scope.tags }
					else if(newAttr.type == 'Field'){ attrs = $scope.fields; }
					else { throw "cannot use " + newAttr.type }

					if(existing){
						_.remove( attrs, function(eattr){ return eattr.id == newAttr.id } )
					}

					var reload = _.noop;
					if(newAttr.type == 'Tag'){ reload = loadTags }
					else if(newAttr.type == 'Field'){ reload = loadFields }
					else { throw "cannot use " + newAttr.type }

					reload( attrs.concat([newAttr]) )		
				
					
					$scope.fieldExpanded = newAttr.group;
					
					PNotify.ok("Attribute <em>" + newAttr.name + "</em> Saved");
					
					return true;
				}, function(err){
					PNotify.err("Could not save new attribute");
					return false;
				});
		}
		
		
		
		/** member tags **/
		

		$scope.tagExpanded = 'none';
		$scope.expandTag = function(t){
			if($scope.tagExpanded == t){
				t = 'none';
			}
			$scope.tagExpanded = t;
		}

		
		$scope.showNewTagForm = false;
		$scope.newTag = { public:false };
		
		$scope.saveNewTag = function(){
			$http.put("/api/v1/team/" + jsPageModel.team.id + "/tags", $scope.newTag)
				.success(function(data){ 
					$scope.showNewTagForm = false;
					if(!data){ return; }
					PNotify.ok("New Tag <em>" + $scope.newTag.name + "</em> Saved");
					loadTags($scope.tags.concat([data]))
					$scope.newTag = {  };
				});
		};
		
		
		
		/** Groups **/
		
		$scope.groups = jsPageModel.groups;
		$scope.rawGroupList = _.keys($scope.groups);
		
		$scope.saveNewDataGroup = function(groupName){
			$http.put("/api/v1/team/" + jsPageModel.team.id + "/groups", groupName) // returns 204
				.success(function(data){
					PNotify.ok("New Group Added");
					$scope.rawGroupList.push(groupName);
					$scope.groups[groupName] = [];
					$scope.newDataGroup = null;
					$scope.showNewGroupForm = false;
				});
		};

		$scope.getPotentialGroupAdminsExcluding = function(currentAdmins)
		{
			var adminIds = currentAdmins;
			return _.filter($scope.teamMembers, function(member){
				return ! _.includes(adminIds, member.memberId);
			});
		}
		
		$scope.newGroupAdmins = {};
		$scope.showAddForm = {};

		$scope.saveNewGroupAdmin = function(group, person){
			
			$http.put("/api/v1/team/" + jsPageModel.team.id + "/group/" + group.groupName + "/admins", person.memberId) // returns 204
				.success(function(data){
					PNotify.ok("Added " + person.firstName + " " + person.lastName + " as an Admin for data group " + group.groupName);
					group.admins = group.admins || [];
					group.admins.push(person.memberId);
					delete $scope.newGroupAdmins[group.groupName];
					$scope.showAddForm[group.groupName] = false;
				});
		};
	}])



	.controller("TeamAdminAdvController", ['$scope', 'BreadSvc', '$http', 'EntitySvc', 'confirmr', function($scope,bread, $http, entities, confirmr){
		bread.setCrumbs( [{label:'Admin',link:'#/admin'}, {label:'Advanced'}] );
		
		$scope.team = entities.team;
		//$scope.teamMembers = [];
		//$http.get("/api/v1/team/" + jsPageModel.team.id + "/members").success(function(data){
		//	$scope.teamMembers = _.sortBy(data, ['lastName']);
		//});

		$scope.terms = jsPageModel.terms;

		/**
		 *  Team Admins
		 */

		 // select loader
		$scope.teamMembers = jsPageModel.teamMembers;

		function isAdmin(member){
			var adminIds = _.map($scope.admins, 'memberId');
			return _.includes(adminIds, member.memberId)
		}

		/** map a role to a list of members */
		function rolesToMembers(roleList, roleType){
			return _(roleList)
				.filter(function(role){ return role.authority == roleType})
				.map(function(role){ return _(jsPageModel.teamMembers)
												.find(function(m){ return m.memberId == role.member })
									})
				.filter(function(m){ return !!(m); })
				.value();
		}
		
		$scope.newAdminHolder = {};
		$http.get("/api/v1/team/" + jsPageModel.team.id + "/roles/?roleType=TEAM_ADMIN").success(function(data){
			$scope.admins = rolesToMembers(data, "TEAM_ADMIN");
		});

		$scope.addNewTeamAdmin = function(person){

			// is person already an admin?
			if(isAdmin(person)){
				PNotify.err("Already a Team Administrator");
				return;
			}

			$http.put("/api/v1/team/" + jsPageModel.team.id + "/admins", person.memberId) // returns 204
				.success(function(data){
					PNotify.ok("Added " + person.firstName + " " + person.lastName + " as an Admin for team " + jsPageModel.team.id);
					$scope.admins = $scope.admins || [];
					$scope.admins = $scope.admins.concat(rolesToMembers([data], "TEAM_ADMIN"));
					$scope.showNewTeamAdmin = false;
					delete $scope.newAdminHolder.newTeamAdmin;
				});
		};
		
		$scope.removeTeamAdmin = function(person){
			
			if($scope.admins && $scope.admins.length == 1){
				PNotify.err("Cannot remove the last admin");
				return;
			}

			if( !confirm("Really remove " + person.firstName + " " + person.lastName + " as a Team Admin?") ){
				return;
			}
			
			// is person already an admin?
			if(!isAdmin(person)){
				PNotify.err("Not a Team Administrator");
				return;
			}

			$http.delete("/api/v1/team/" + jsPageModel.team.id + "/admins/" + person.memberId) // returns 200
				.success(function(data){
					PNotify.ok("Removed " + person.firstName + " " + person.lastName + " as an Admin for team " + jsPageModel.team.id);
					$scope.admins = _($scope.admins).filter(function(el){ return el.memberId != person.memberId}).value();
				});
		};
		




		/**
		 * Membership Levels
		 */ 

		$scope.newMembershipLevelHolder = {}
		
		$scope.membershipLevels = {}
        $scope.membershipLevels = partitionMembershipLevels(jsPageModel.membershipLevels);

		$scope.addNewMembershipLevel = function(type, name){

			var newML = { member: (type === 'member'), name:name};

			if( _.some( $scope.membershipLevels.members,  {name:name} ) || _.some( $scope.membershipLevels.nonMembers,  {name:name} )  )
			{
				PNotify.err("Membership level: '" + name + "' already exists");
				return;
			}
			
			if(type === 'member'){
				$scope.membershipLevels.members.push(newML);
			}
			if(type === 'nonMember'){
				$scope.membershipLevels.nonMembers.push(newML);
			}

			updateMembershipLevels();
		}

		$scope.moveUp = function(arr, fromPos){
			arr.move(fromPos, fromPos+1);
			updateMembershipLevels();
		}

		$scope.moveDown = function(arr, fromPos){
			arr.move(fromPos, fromPos-1);
			updateMembershipLevels();
		}

		$scope.renameMembershipLevel = function(oldName)
		{
			var body = {
				tpl: 'editMembershipLevel.tpl',
				model: {
					name: oldName
				}
            };

			confirmr.confirm("Rename Membership Level: " + oldName + "?", body, "Save", "Cancel", 'md')
					.then(function(){

						var patchObj = {};
			    		patchObj[oldName] = body.model.name;

						$http.patch("/api/v1/team/" + jsPageModel.team.id + "/pref/MembershipLevels", patchObj )
								.success(function(data){ 
									jsPageModel.membershipLevels = data.list;
									$scope.membershipLevels = partitionMembershipLevels(data.list);
								})
								.success(function(){PNotify.ok(oldName + " and all members updated to: " + body.model.name )})
					})
			
		}

		$scope.deleteMembershipLevel = function(oldName)
		{
			confirmr.confirm("Delete level?", "Do you really want to delete this membership level <strong>" + oldName + "?</strong>" , "Yes", "No!").then(function(res){
				var patchObj = {};
			    patchObj[oldName] = null; // PATCH with null value
				$http.patch("/api/v1/team/" + jsPageModel.team.id + "/pref/MembershipLevels", patchObj )
					.success(function(data){ 
						jsPageModel.membershipLevels = data.list
						$scope.membershipLevels = partitionMembershipLevels(data.list);
					})
					.success(function(){PNotify.ok(oldName + " - Deleted")})
			})
			
		}

		

		function updateMembershipLevels(){
			var allLevels = $scope.membershipLevels.members.concat($scope.membershipLevels.nonMembers);
			$http.put("/api/v1/team/" + jsPageModel.team.id + "/pref/MembershipLevels", {list:allLevels, '@class' : 'com.sardb.cohort.entity.TeamPrefVal$MembershipLevels'} )
		}
		

		
		
		
		
	}])

















	
	.controller("TeamMemberController", ['$scope', 'BreadSvc', '$http', function($scope,bread, $http){
		bread.setCrumbs(  {label:'Admin',link:'#/admin'}, {label:'New Member'} );
		
		$scope.member = { team: jsPageModel.team.id, account: {} };
		
		$scope.membershipLevels = [];

		$http.get("/api/v1/team/" + jsPageModel.team.id + "/pref/MembershipLevels").success(function(data){
			$scope.membershipLevels = data.list;
		});

		$scope.membershipLevelGroup = function(ml)
		{
			return ml.member ? 'MRT Member' : 'Non-MRT Member';
		}
		
		$scope.checkExistingAccount = function(email){
			  $http.post("/api/v1/team/" + jsPageModel.team.id + "/checkExistingAccount", { email: email })
							.success(function(data){
								if( data == null || data === '' || data.length == 0){
									$scope.newMemberMode = 'newAccount'
								}
								else {
									if(data.length > 0){
										angular.extend($scope.member.account, data[0])
										$scope.newMemberMode = 'useExisting'
									}
									else{
										alert("An error has occurred: code CEA-001");
									}
								}
							});
		}
		
		$scope.save = function(){
			$http.put("/api/v1/team/" + jsPageModel.team.id + "/members", $scope.member)
			.success(function(){ PNotify.ok("Team Member Added: OK"); });
		};
	}])












	.controller("TeamAdminTagIntegrationsController", ['$scope', 'BreadSvc', '$http', 'EntitySvc', 'confirmr', '$interval', function($scope,bread, $http, entities, confirmr, $interval){

		$scope.newTagActivity = {config:{}};
		$scope.newOauthCredentials = {};

		$http.get("/api/v1/team/"+ jsPageModel.team.id + "/memberAttributeDefs").then(function(resp){
			
			$scope.tags = _(resp.data).filter({type:'Tag', team:jsPageModel.team.id}).sortBy(["group", "name"]).value();

			$http.get("/api/v1/team/"+ jsPageModel.team.id + "/pref/TagIntegration?multiple=true").then(function(resp){
				$scope.integrations = resp.data;

				// use the tag def list above to provide names for the loaded tagintegrations
				$scope.integrations.forEach(function(i){
					var td = _.find($scope.tags, function(t){
						return t.id == i.tagDefId
					})
					if(!td){
						i.tag = null
					}
					else {
						i.tag = td;
					}
				})
			})

		})

		

		$scope.updateCredentialsDomain = function(item, model){
			
			var indexOfAt = item.email.indexOf("@");
			var domain = item.email.substring(indexOfAt+1);
			$scope.newTagActivity.config.groupDomain = domain;
			$scope.newTagActivity.config.credentialsKey = item.team + ":" + item.email;
		}

		$scope.updateSelectedGroup = function(item, model){
			
			$scope.newTagActivity.config.groupId = item.id;
			$scope.newTagActivity.config.groupName = item.email;
		}

		$scope.loadGroups = function(){
			$http.get("/api/v1/team/"+ jsPageModel.team.id + "/googleGroups?credentialsKey=" + $scope.newTagActivity.config.credentialsKey + "&domain=" + $scope.newTagActivity.config.groupDomain)
				.then(function(resp){
					$scope.loadedGroups = resp.data;
				})
		}

		$scope.newTagIntegration = function()
		{
			var src = $scope.newTagActivity;
			var ti = {"@class":"com.sardb.cohort.entity.TeamPrefVal$TagIntegration"};
			ti.tagDefId = src.selectedTag.id;
			ti.type = src.type;
			ti.config = {};
			ti.config.credentialsKey = src.config.credentialsKey;
			ti.config.groupId = src.config.groupId;
			ti.config.groupName = src.config.groupName;
			$http.post("/api/v1/team/"+ jsPageModel.team.id + "/pref/TagIntegration", ti).then(function(resp){
				$scope.integrations.push(ti);
				PNotify.ok("Saved!")
				$scope.newTagActivity = {config:{}};
			})
		}




		function loadOAuthCredentials()
		{
			$http.get("/api/v1/team/"+ jsPageModel.team.id + "/oauthCredentials").then(function(resp){
				$scope.oauthCredentials = resp.data;

				$scope.oauthCredentials.forEach(function(cred){
					cred.daysLeft = (cred.credentials.expirationTimeMilliseconds - new Date().getTime()  ) / 86400000
				})

			})
		}
		loadOAuthCredentials();

		$scope.deleteOauthCredential = function(cred){
			$http.delete("/api/v1/team/"+ jsPageModel.team.id + "/oauthCredentials?email=" + cred.email).then(function(resp){
				PNotify.ok("Credential Deleted!")
				loadOAuthCredentials();
			})
		}

		$scope.goNewOauthCredentials = function(opts){
			if(opts.provider == 'Google'){
				
				var url = SARDB.url("/googleOAuth2request?teamAdmin=" + jsPageModel.team.id + ":" + opts.email)
				opts.provider = null;
				opts.email = null;
				var win = window.open(url, "Google OAuth Request")

				var pollTimer   =   $interval(function() { 
                try {
                    if(win.closed){
						$interval.cancel(pollTimer)
						loadOAuthCredentials();
						$scope.showNewOCred = false;
					}
                } catch(e) {
                }
            }, 500);
			}

			
		}


	}])


	.directive("validityInput", function(){

		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, elem, attrs, ngModel) {
				console.log(scope, elem, attrs, ngModel)

				var regex = /^([0-9]*)([DMY])$/
				var getQuan = function(whole){
					console.log("getQuan: " + whole)
					var q = regex.exec(whole);
					if(!q){ return ""}
					else { return q[1] }
				}
				var getQual = function(whole){
					console.log("getQual: " + whole)
					var q = regex.exec(whole);
					if(!q){ return ""}
					else { return q[2] }
				}

				if(attrs.type == 'radio'){
					ngModel.$formatters.push(function(model){
						return getQual(model);
					});
      				ngModel.$parsers.push(function(user){
						return getQuan(ngModel.$modelValue) + user
					});
				}
				if(attrs.type == 'number')
				{
					ngModel.$formatters.push(function(model){
						return getQuan(model);
					});
      				ngModel.$parsers.push(function(user){
						return user + getQual(ngModel.$modelValue) 
					});
				}
				

			}
		}

	})

	
	function indexer(collection, path){
		var index = {};
		_.forEach( collection, function(def){
			var prop = _.get(def, path);
			if(!prop){
				return;
			}
			else {
				if( ! _.isArray(index[prop]) ){
					index[prop] = [];
				}
				index[prop].push(def);
			}	
		});
		return index;
	}


	function isMember(ml)
	{
		return ml.member;
	}

	function partitionMembershipLevels(data){
		var membershipLevels = {}
		membershipLevels.members = _.filter(data, isMember);
		membershipLevels.nonMembers = _.filter(data, _.negate(isMember));
		return membershipLevels;
	}

	function _isTrue(b){
		return b === true;
	}

	function isTag(attr){
		return attr.type == 'Tag';
	}

	function isField(attr){
		return attr.type == 'Field';
	}

	function isAppRole(attr){
		return attr.type == 'AppRole';
	}

	function push(obj, propWithArray, val)
	{
		if( ! _(obj).has(propWithArray) ) {
			obj[propWithArray] = [];
		}
		obj[propWithArray].push(val);
	}
	
	
})();

'use strict'
;(function(){
	
	/**
	 * Angular module for TeamMember Object Actions 
	 */
	angular.module('sbTeamMember', ['ngRoute'])
	
	.config(['$routeProvider',
	  function($routeProvider) {
	    $routeProvider.
	      when('/', {
	        redirectTo: '/view'
	      }).
	      when('/view', {
	        templateUrl: '/gen/partials/teamMember/view.html', 
	        controller: 'TeamMemberViewController'
	      }).
				when('/edit', {
	        templateUrl: '/gen/partials/teamMember/edit.html', 
	        controller: 'TeamMemberEditController'
	      }).
	      otherwise({
	        redirectTo: '/view'
	      });
	  }])
	  
	  .run([ 'BreadSvc', function(bread){
	  	var team = jsPageModel.team;
	  	var member = jsPageModel.member;
	  	
	  	bread.setBase( [{label:team.id, link:SARDB.url('/team/' + team.id) },  {label:member.username, link:SARDB.url('/team/' + team.id + "/member/" + member.id + "#/view" ) }] );
	  	
	  }])
	  
	  .factory('EntitySvc', function(){
	  		return {
	  			team: jsPageModel.team,
	  			member: jsPageModel.member
	  		};
	  })

	  .filter('isEmpty', function () {
        return function (obj) {
            return _.keys(obj).length == 0;
        };
	  })

	  .filter('attrType', function () {
        return function (attrs, type) {
			return _(attrs).filter(function(attr, truthy){
				return (attr.def.type == type )
			}).value()
        };
	  })

	.run(['$rootScope', function ($rootScope) 
	{
		$rootScope.openHelp.module = "member";
	}])
	  
	  
	


	/**
	 * Controller for the modal window for selecting a new attribute to assign
	 *
	 */
	.controller("AttrPickerModalController", ['attrs', 'onSelect', 'close', '$element','$scope', function(attrs, onSelect, close, $element, $scope){
		$scope.attrs = attrs;
		
		$scope.cancel = function(){
			$element.modal('hide');
			close("cancel", 500);
		};
		
		$scope.select = function(attr){
				$element.modal('hide');
				close("ok", 500);
				onSelect(attr)
		};
	}])
	









	/**
	 * Provides the general view of a team member
	 */
	.controller("TeamMemberViewController", ['$scope', 'BreadSvc', 'EntitySvc', '$http', '$timeout', 'ModalService', '$q', 'Upload', 'attrService',
												function($scope,bread,entities,$http,$timeout,modal,$q,Upload, attrService){
		bread.setCrumbs(  {label:'Details'} );
		
		$scope.member = entities.member;
		$scope.team = entities.team;
		
		/*
		 * fields
		 */
		
		//$scope.fields = []; // TODO del?
		var allFields = [];
		var allTags = [];

		$scope.setAttributes = {};
		$scope.unsetAttributes = {};
		
		$http.get("/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/attrs").success(function(data){
			//{ category1: { group1: [tags...], group2: [tags...] }, category2: { group1: [tags...], group2: [tags...] },  }			
			allFields = data;
			var partitioned = _.partition(data, fieldTagIsSet);
			categoriseAndGroup($scope.setAttributes, partitioned[0]);
			group($scope.unsetAttributes, partitioned[1])
		});

		var addAttribute = function(attr)
		{
			if(attr.def.type == 'Tag'){
				addTag(attr)
			}
			else {
				addField(attr)
			}
		}

		$scope.showAttrPickerModal = function(){
			return modal.showModal({
				templateUrl: "attrPickerModal.html",
				controller: "AttrPickerModalController",
				inputs: {
					attrs: $scope.unsetAttributes,
					onSelect: addAttribute
				}
			}).then(function(modal) {
		      modal.element.modal();
		      return modal;
		    });
		}
		
		var onFieldAdd = function onFieldAdd(newField){
			applyField(newField)
			PNotify.ok("Added Attribute: " + newField.def.name );
		}

		var onFieldUpdate = function onFieldUpdate(field){
			PNotify.ok("Edited Attribute: " + field.def.name );
		}

		var onFieldDelete = function onFieldDelete(field){
			unapplyField(field);
			PNotify.ok("Removed Attribute: " + field.def.name );
		}

		/**
		 * add field attribute
		 */
		var addField = function(field){
			field.instance = {};
			attrService.addField(field, $scope.team.id, $scope.member.id, onFieldAdd)
		};


		/*
		 * Edit field attribute instance
		 */
		$scope.editField = function(field){
			attrService.editField(field, $scope.team.id, $scope.member.id, onFieldUpdate, onFieldDelete)
		};
			

		// adds it to the display model
		// removes it from the addition menu
		var applyField = function(field){
			categoriseAndGroup($scope.setAttributes, [field], 'fields');
			removeFromGrouped($scope.unsetAttributes,field); // remove from the "unset" fields, using the match func.
		}

		var removeFromGrouped = function(groups, field){
			var group = groups[field.def.group]
			if(!group){ return; }
			_.remove(group, function(afield){
				return afield.def.id == field.def.id;
			})
			if(group.length == 0){
				delete groups[field.def.group];
			}
		}
		
		var unapplyField = function(field){ // put the field back in the "unset" list
			field.instance = null;
			group($scope.unsetAttributes, [field] )
			_.remove($scope.setAttributes[field.def.displayGroup][field.def.group]['fields'], function(afield){ return afield.def && afield.def.id == field.def.id; } )
		};
		















		/* local changes after adding a new tag */
		var onTagAdd = function onAdd(tag){
			applyTag(tag)
			PNotify.ok("Added Tag: " + tag.def.name + " for " + $scope.member.username);
		}

		var onTagSave = function onTagSave(tag){
			PNotify.ok("Updated Tag: " + tag.def.name + " for " + $scope.member.username);
		}

		var onTagDelete = function onTagDelete(tag){
			unapplyTag(tag)
			PNotify.ok("Removed Tag: " + tag.def.name + " for " + $scope.member.username);
		}


		/* Picked a new tag to apply, show the modal */
		var addTag = function(tag){

			tag.instance = {
				value: null
			};

			attrService.addTag(tag, $scope.team.id, $scope.member.id, onTagAdd)
		};

		$scope.editTag = function(tag){
			attrService.editTag(tag, $scope.team.id, $scope.member.id, onTagSave, onTagDelete)
		};


		var applyTag = function(tag){
			categoriseAndGroup($scope.setAttributes, [tag], 'tags');
			removeFromGrouped($scope.unsetAttributes,tag)	
		}
		
		var unapplyTag = function(tag){
			tag.instance = null;
			group($scope.unsetAttributes, [tag] )
			_.remove($scope.setAttributes[tag.def.displayGroup][tag.def.group]['tags'], function(t){ 
				return t.def && t.def.id == tag.def.id; 
			} )
		};


		
		

		
		











		/* files */

		$scope.memberFiles = [];
		$scope.newFile = { show: false};

		$scope.upload = function(file, description){
			Upload.upload({
				url: "/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/upload",
				fields: {'comment': description}, // additional data to send
				file: file
			}).progress(function (evt) {
				var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
			}).success(function (data, status, headers, config) {
				console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
				$scope.memberFiles.push(data)
				PNotify.ok("File Upload Success")
				$scope.newFile.file = null;
				$scope.newFile.comment = null;
				$scope.newFile.show = false;
			});
		}

		$scope.deleteFile = function(fileId, index){
			$http.delete("/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/file/" + fileId).then(function(resp){
				$scope.memberFiles.splice(index,1)
				PNotify.ok("File deleted OK")
			})
		}

		$scope.canDeleteFile = function(file){
			return file.access === 'editable';
		}

		$http.get("/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/files")
			.then(function(resp){
				$scope.memberFiles = resp.data;
			})



		/* comments & history */
		$scope.auditLog = [];
		$scope.newComment = {msg:null}
		
		$scope.addComment = function(){
			if(!$scope.newComment.msg){
				return;
			}
			$http.post("/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/log", $scope.newComment)
			.then(function(resp){
				$scope.newComment.msg = null;
				$scope.auditLog.unshift(resp.data);
			})
		}

		$http.get("/api/v1/team/" + $scope.team.id + "/member/" + $scope.member.id + "/log")
			.then(function(resp){
				$scope.auditLog = resp.data;
			})

	}])
	
	
	
	
	/**
	 * Provides the editting & mgmt of a team member
	 */
	.controller("TeamMemberEditController", ['$scope', 'BreadSvc', 'EntitySvc', '$http', '$timeout', 'ModalService','$location', 'confirmr', 'attrService'
				 , function($scope,bread,entities,$http,$timeout,modal,$location, confirmr, attrService){
		bread.setCrumbs(  {label:'Details'} );
		
		$scope.member = entities.member;
		$scope.memberCopy = angular.copy($scope.member)
		
		$scope.membershipLevels = [];

		$http.get("/api/v1/team/" + jsPageModel.team.id + "/pref/MembershipLevels").success(function(data){
			$scope.membershipLevels = data.list;
		});

		$scope.membershipLevelGroup = function(ml)
		{
			return ml.member ? 'MRT Member' : 'Non-MRT Member';
		}	
		
		$http.get("/api/v1/team/" + entities.team.id + "/member/" + $scope.member.id + "/access" ).success(function(data){
			$scope.memberAccess = data;
		})
		
		$scope.save = function(form){
			//console.log(form)

			if(form.$pristine){
				PNotify.ok("No changes :)");
				return;
			}



			$http.patch("/api/v1/team/" + entities.team.id + "/member/" + $scope.member.id, $scope.member)
			.success(function(){ 
						PNotify.ok("Member Updated OK!");
						window.location.reload();
			});
		};
		
		$scope.updateAccess = function(request){
			$http.put("/api/v1/team/" + entities.team.id + "/member/" + $scope.member.id + "/access", request)
			.success(function(data){ $scope.memberAccess = data;  });
		};
		
		$scope.deleteMember = function(){
			if(confirm("Are you sure!!")){
				$http.delete("/api/v1/team/" + entities.team.id + "/member/" + $scope.member.id).success(function(data){
					PNotify.ok("DELETED! goodbye " + $scope.member.account.firstName);
					var next = SARDB.url('/team/' + entities.team.id)
					setTimeout(function(){
						location.href = next;
					}, 1200);
					
				})
			}
		}

		$scope.changeUsername = function(){

			var username_parts = $scope.member.username.split("@");

			var body = {
				tpl: 'editUsername.tpl',
				model: {
					userPart: username_parts[0],
					team: username_parts[1]
				}
            };

			confirmr.confirm("Change Username", body, "Update", "Cancel", "md").then(function(res){
				var newUsername = body.model.userPart + '@' + body.model.team;
				$http.patch("/api/v1/team/" + entities.team.id + "/member/" + $scope.member.id + "/_selected", {username:newUsername})
					.success(function(){ 
						$scope.member.username = newUsername;
						PNotify.ok("Member Updated OK!"); 
					});
				})
		}

		$http.get("/api/v1/team/" + jsPageModel.team.id + "/member/" + $scope.member.id + "/attrs?types=AppRole").success(function(data){
			$scope.appRoles = nest(data, ['def.app', 'def.group']);

			updateAccessDisplay($scope.appRoles)
		});

		$scope.onAccessChange = function(name,groupName,role)
		{
			var id = (typeof role == 'string') ? role : role.def.id;
			// remove all other tags
			var roles = $scope.appRoles[name][groupName]
			_(roles).each(function(role){
				if( _.get(role,'def.id') == id ){
					// enable if disabled
					if(!role.instance){
						role.instance = { value:'--' };
						attrService.updateAttrNoModal(role,jsPageModel.team.id,$scope.member.id )
									.then(function(data){ 
										role.instance = data.instance; 
										updateAccessDisplay($scope.appRoles)
										PNotify.ok("Added App Permission: " + role.def.name);
									})
					}
				}
				else {
					// disable if enabled
					if(role.instance){
						delete role.instance;
						attrService.deleteAttrNoModal(role,jsPageModel.team.id,$scope.member.id )
									.then(function(data){ 
										 updateAccessDisplay($scope.appRoles);
										 PNotify.ok("Removed App Permission: " + role.def.name);
									})
					}
				}
			})
		}
	}])

	

	function updateAccessDisplay(appRoles){
		_(appRoles).each(function(group, app){
			_(group).each(function(roles, groupName){
				var groupAccess = false;
				_(roles).each(function(role){
					if(!groupAccess && !!(role.instance) ){
						groupAccess = role.def.id;
					}
				})
				if(!groupAccess){
					groupAccess = "NO_ACCESS";
				}
				roles.active = groupAccess;
			})
		})
	}

	var notNull = function(field){
		return !_.isNull(field.value);	
	};
	
	var fieldTagIsSet = function(ft){
		return ft.instance != null
	}

	
	/*
	var isNullAndEditable = function(field){ 
		return ( (_.isNull(field.value)) && field.type=='Editable');
	};
	*/
	/*
	var tagIsUnsetAndEditable = function(tag){ 
		return ( (tag.instance == null ) && tag.canEdit==true);
	};
	*/
	
	/**
	 * organises tags/fields for display:-   category:{ group: [{a},{b}] }
	 */
	function categoriseAndGroup(holder, arr)
	{
		
		_.forEach(arr, function(item){

			var category = _.get(item, 'def.displayGroup');
			if(!category){
				category = "Misc"
			}
			if( ! _.isObject(holder[category]) )
			{
				holder[category] = {};
			}

			var group = _.get(item, 'def.group')
			if(!group){
				group = "No Group"
			}

			if( ! _.isObject(holder[category][group] ) )
			{
				holder[category][group] = {tags:[], fields:[]};
			}

			var type = item.def.type.toLowerCase() + "s"

			holder[category][group][type].push(item)
		})
	}

	/**
	 * organise tags/field that can be added by this user:-   group: [ {a},{b} ]
	 */
	function group(holder, arr)
	{
		_.forEach(arr, function(item){

			if(item.type == 'readOnly' || item.access == 'readOnly' || item.def.permission == 'readOnly'){
				// ignore this one.
				return;
			}

			var group = _.get(item, 'def.group')
			if(!group){
				group = "No Group"
			}

			if( ! _.isArray(holder[group] ) )
			{
				holder[group] = [];
			}
			holder[group].push(item)
		})
	}
	
	
	
})();

/**
 * @author Rob Shepherd
 */
'use strict';

;(function(){

angular.module('sbAttrs', ['angularModalService'])

  /**
	 * Controller for the modal window for editing member fields
	 * param: field - a field object
	 * param: close - a function called on close
	 * param: $element - the JQ dom element being operated on
	 * param: update - a function that provides "update" functionality, returns a promise
	 * param: remove - a function that provides "remove" functionality, returns a promise
	 * param: initialAdd - a boolean that indicates if this is the time when the field has been added
	 * param: $scope - ng provided Scope object.
	 */
	.controller("FieldEditModalController", ['field', 'close', '$element', 'initialAdd', '$scope', function(field, close, $element, initialAdd, $scope){
		$scope.field = field;
		$scope.initialAdd = initialAdd;
		
		if(!field.instance){
			field.instance = { def: field.def.id }
		}

		var orig = angular.copy(field); // because we're operting on the actual field, we make a copy first.
		
		$scope.cancel = function(){
			field.instance.value = orig.instance.value; // restore the value
			$element.modal('hide');
			close("cancel", 500); // mark as cancelled
		};
		
		$scope.ok = function(){
			if( field.instance.value == orig.instance.value ){ 
				$element.modal('hide');
				close("cancel", 500);
			}
			else if( isEmpty(field.instance.value) ){
				PNotify.err(field.def.name + " cannot be empty");
			}
			else {
				$element.modal('hide');
				close("ok", 500);
			}
		};

		$scope.remove = function(){
			field.instance = {} // restore the value
			$element.modal('hide');
			close("remove", 500); // mark as cancelled
		};
	}])
	
	
	/**
	 * Controller for the modal window for editing member fields
	 * param: tag - a tag object
	 * param: close - a function called on close
	 * param: $element - the JQ dom element being operated on
	 * param: update - a function that provides "update" functionality, returns a promise
	 * param: remove - a function that provides "remove" functionality, returns a promise
	 * param: initialAdd - a boolean that indicates if this is the time when the tag has been added
	 * param: $scope - ng provided Scope object.
	 */
	.controller("TagEditModalController", ['tag', 'close', '$element', 'initialAdd', '$scope', function(tag, close, $element, initialAdd, $scope){
		var orig = angular.copy(tag);
		
		$scope.tag = tag;
		$scope.initialAdd = initialAdd;
		
		if( initialAdd && tag.def.defaultExpiry && !$scope.tag.instance.expireDate )
		{
				$scope.tag.instance.expireDate = calculateDefaultExpiry(tag.def.defaultExpiry);
				$scope.tag.instance.startDate = moment().format("YYYY-MM-DDTHH:mm:ssZ");

		}
		//console.log($scope.tag.instance.startDate, $scope.tag.instance.expiryDate)

		
		
		
		$scope.cancel = function(){
			tag.instance = orig.instance;
			$element.modal('hide');
			close("cancel", 500);
		};
		
		$scope.ok = function(){
			//console.log("TODO: tag.isDirty?"); // multi-field complex dirty - form?
			//console.log("TODO: tag.isValid?"); // multi-field validation - form?
			if(false /* ! tag.isDirty*/ ){
				$element.modal('hide');
				close("cancel", 500);
			}
			else {
				$element.modal('hide');
				close("ok", 500);
				/*
				update(tag, initialAdd).then(function(success){
					if(success){
						$element.modal('hide');
						close("edit", 500);
					}
					else {
						throw "Error saving tag";
					}
				});
				*/
			}
		};
		
		$scope.remove = function(){
			tag.instance = null;
			$element.modal('hide');
			close("remove", 500);
			/*
			remove(tag).then(function(success){
				if(success){
					$element.modal('hide');
					close("remove", 500);
				}
				else {
					throw "Error deleting tag";
				}
			});
			*/
		};

		$scope.onSaveStart = function(){
			var newExp = calculateDefaultExpiry(tag.def.defaultExpiry, tag.instance.startDate);
			if(confirm("Update expiry to: " + moment(newExp).format("DD/MM/YY")) ){
					tag.instance.expireDate = newExp
			}
		}
	}])


  /**
   * 
   */
  .service("attrService", ['$http', 'ModalService', '$q', function($http, modal, $q)
	{
			/**
			 * update attr via API
			 */
      function updateAttr(tag, team, memberId){
        
        if(!tag.instance){
          PNotify.err(tag.def.name + " - nothing set");
          return false;
        }

        if(  tag.def.type == 'Field' && !tag.instance.value){
          PNotify.err(tag.def.name + " - no attribute value set");
          return false;
        }

        if(tag.def.type == 'Tag' && !tag.instance.value){
          tag.instance.value = '--';
        }

        return $http.post("/api/v1/team/" + team + "/member/" + memberId + "/attr/" + tag.def.id, tag.instance)
            .then(function(resp){
								return resp.data;
            }, function(){
              	return false;
            })
      };

      /** Delete an attr instance over API */
      function deleteAttr(attr, team, memberId){
        attr.instance = null;
        return $http.delete("/api/v1/team/" + team + "/member/" + memberId + "/attr/" + attr.def.id)
          .then(function(resp){
            return attr;
          })
      };



			/* FIELDS */



      function showFieldEditModal(field, firstEdit){
        return modal.showModal({
            templateUrl: "/gen/partials/common/attr/fieldEditModal.html",
            controller: "FieldEditModalController",
            inputs: { // provided to controller constructor named dependencies
              field: field,
              initialAdd: firstEdit
            }
          }).then(function(modal) {
            modal.element.modal();
          	return modal;
          });
      };

      function editField(field, team, memberId, onUpdate, onDelete)
      {
          return showFieldEditModal(field, false)
              .then(function(modal){

                  // once the modal closes, we pick it up here.
                  modal.close.then(function(res){
                    if(res == 'ok'){
                      return updateAttr(field, team, memberId)
                            .then(function(savedField){
																onUpdate(savedField);
                              })
                    }
                    else if(res == 'remove'){
                      return deleteAttr(field, team, memberId)
                            .then(function(deletedField){
																onDelete(deletedField);
                              })
                    }
                })
          })
      }


			function addField(field, team, memberId, onAdd)
			{
					return showFieldEditModal(field, true)
						.then(function(modal){

							// once the modal closes, we pick it up here.
							modal.close.then(function(res){
								if(res == 'ok'){
									return updateAttr(field, team, memberId)
												.then(function(newField){
														onAdd(newField)
												})
								}
							})
						})
			}

			


			/* TAGS */




			function showTagEditModal(tag, firstEdit){
				return modal.showModal({
						templateUrl: "/gen/partials/common/attr/tagEditModal.html",
						controller: "TagEditModalController",
						inputs: {
							tag: tag,
							initialAdd: firstEdit,
						}
					}).then(function(modal) {
						modal.element.modal();
						return modal;
					});
			};



			function editTag(tag, team, memberId, onUpdate, onDelete)
			{
				return showTagEditModal(tag, false)
					.then(function(modal){

						// once the modal closes, we pick it up here.
						modal.close.then(function(res){
							if(res == 'ok'){
							return updateAttr(tag, team, memberId)
									.then(function(savedTag){
										onUpdate(savedTag);
									})
							}
							else if(res == 'remove'){
							return deleteAttr(tag, team, memberId)
									.then(function(deletedTag){
										onDelete(deletedTag);
									})
							}
						})
				})
			}


			function addTag(tag, team, memberId, onAdd)
			{
					return showTagEditModal(tag, true)
						.then(function(modal){

							// once the modal closes, we pick it up here.
							modal.close.then(function(res){
								if(res == 'ok'){
									return updateAttr(tag, team, memberId)
												.then(function(newTag){
														onAdd(newTag);
												})
								}
							})
						})
			}




      return {
        editField: editField,
		addField: addField,
		editTag: editTag,
		addTag: addTag,
		updateAttrNoModal: updateAttr,
		deleteAttrNoModal: deleteAttr
      };

  }]);


	var isEmpty = function(value){
		return _.isUndefined(value) || _.isNull(value) || value == "";
	};

	function calculateDefaultExpiry(defaultExpiryCode, start)
	{
			if(!defaultExpiryCode){
				return;
			}

			var myRegexp = /^([0-9]{1,3})([dDmMyY])$/;
			var match = myRegexp.exec(defaultExpiryCode);
			
			var quan = match[1];
			var qual = match[2];

			var then = moment(start);
			if(qual == 'D'){
				then = then.add(quan, 'days')
			}
			else if(qual == 'M'){
				then = then.add(quan, 'months')
			}
			else if(qual == 'Y'){
				then = then.add(quan, 'years')
			}

			return then.format("YYYY-MM-DDTHH:mm:ssZ");
	}

})();
